export enum SupportStatus {
  OPENING = 'opening',
  SOLVED = 'solved'
}

export interface Support {
  id: number
  type: string
  subject: string
  email: string
  message: string
  attchment: string
  status: SupportStatus
  created_at: string
}

export type PartialSupport = Partial<Support>;
