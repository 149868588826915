
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getSupport } from '@/services/api';

import { PartialSupport, SupportStatus } from '@/interfaces/Support';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialSupport & {} = {
  type: '',
  subject: '',
  email: '',
  message: '',
  attchment: '',
  status: SupportStatus.OPENING,
  created_at: ''
};

export default defineComponent({
  setup() {
    const supportId = useRoute().params.id as string;
    const data = ref(DEFAULT_FORM_VALUES);

    onMounted(async() => {
      const res = await getSupport({ supportId });
      data.value = res.data;
    });

    return {
      data
    };
  }
});
